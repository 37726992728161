<template>
    <Suspense>

        <v-app id="inspire">

            <v-app-bar>

                <v-btn icon="mdi-arrow-left" href="/"></v-btn>
                <v-app-bar-title><img src="https://atmicup-cdn.portbyte.id/logo_new_compressed.png" width="60px" /></v-app-bar-title>
                <!-- <v-spacer></v-spacer> -->

                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn color="primary" v-bind="props">
                            <v-avatar size="32" class="ma-1">
                                <img :src="avatar" alt="Avatar" width="25px"
                                     />
                            </v-avatar>
                            &nbsp;
                            <small>{{ user_name.substring(0, 15) }}</small>
                            <v-icon color="primary" right>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item ripple href="https://accounts.google.com">
                            <v-list-item-title>Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item ripple @click="logout()">
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-app-bar>

            <v-main class="grey lighten-4" style="background-color: #F5F5F5; height: 100vh; ">
                <slot />
            </v-main>
            <!-- <v-footer style="bottom:0; flex: 0 0 !important;">
                <v-col align="center" cols="12">
                    {{ new Date().getFullYear() }} — <strong>ATMI CUP</strong>
                </v-col>
            </v-footer> -->

        </v-app>
        <template #fallback>
            <h1>Loading...</h1>
        </template>
    </Suspense>
</template>

<script setup>
import { ref } from 'vue'

const drawer = ref(null)
const auth = useAuthStore()
let user_name = auth.user_data.name
let avatar = auth.user_data.picture

function logout() {
    auth.clean();
    navigateTo('/login')
}
</script>

<script>
export default {
    data: () => ({ drawer: null }),
}
</script>